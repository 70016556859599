import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import { EffectCreative } from "swiper/modules";
import affiliates from "assets/swiperassets/admin-afiliados.png";
import clients from "assets/swiperassets/admin-clientes.png";
import dashboard from "assets/swiperassets/admin-dashboard.png";
import polos from "assets/swiperassets/admin-polos.png";
import saldo from "assets/swiperassets/admin-saldo.png";
import './ArtSection.css';

const ArtSection = () => {
  return (
    <Swiper
      grabCursor={true}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: false,
          translate: [0, 0, -400],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      }}
      modules={[EffectCreative]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="swiper-content">
          <h2>Velocidade e Integração Completa</h2>
          <img src={affiliates} alt="Painel de afiliados" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-content">
          <h2>Layout Flexível, Resultados Rápidos</h2>
          <img src={clients} alt="Painel de afiliados" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-content">
          <h2>Software Ágil, Layout Personalizável</h2>
          <img src={dashboard} alt="Painel de afiliados" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-content">
          <h2>Integrações Fáceis, Rapidez Garantida</h2>
          <img src={polos} alt="Painel de afiliados" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-content">
          <h2>Controle Total com Velocidade</h2>
          <img src={saldo} alt="Painel de afiliados" />
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default ArtSection;
