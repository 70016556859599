import React from 'react';
import './StatusLabel.css';

const StatusLabel = ({ text, color }) => {
  return (
    <span className="status-label" style={{ backgroundColor: color }}>
      {text}
    </span>
  );
};

export default StatusLabel;
