import React, { useState } from "react";
import './RetainedCard.css'; // Arquivo de estilos para o componente
import retainedIcon from 'assets/logo-black.png'; // Substitua pelo caminho correto do PNG

const RetainedCard = ({
  title, // Título do card (ex: "Saldo Retido")
  description, // Descrição do card (ex: "Seu saldo retido em transações")
  retainedData, // Objeto com valores retidos por método (ex: { pix: 100, creditCard: 200, boleto: 300 })
  additionalInfo, // Qualquer informação adicional
}) => {
  const [selectedMethods, setSelectedMethods] = useState([]);

  // Função para calcular o saldo retido total com base nos métodos selecionados
  const calculateRetainedTotal = () => {
    let total = 0;
    if (selectedMethods.includes("pix")) {
      total += retainedData.pix;
    }
    if (selectedMethods.includes("creditCard")) {
      total += retainedData.creditCard;
    }
    if (selectedMethods.includes("boleto")) {
      total += retainedData.boleto;
    }
    return total;
  };

  // Função para gerenciar a seleção de métodos
  const handleMethodClick = (method) => {
    if (selectedMethods.includes(method)) {
      setSelectedMethods(selectedMethods.filter((m) => m !== method));
    } else {
      setSelectedMethods([...selectedMethods, method]);
    }
  };

  return (
    <div className="retained-card">
      <div className="retained-card-header">
        <h2 className="retained-card-title">{title}</h2>
        {/* Exibe a imagem PNG no canto superior direito */}
        <div className="retained-card-icon">
          <img src={retainedIcon} alt="Retained Icon" className="retained-card-icon-image" />
        </div>
      </div>
      <p className="retained-card-description">{description}</p>
      <h3 className="retained-card-amount">R$ {calculateRetainedTotal()}</h3> {/* Exibe o valor retido calculado */}
      <p className="retained-card-additional">{additionalInfo}</p> {/* Informação adicional opcional */}

      {/* Botões para selecionar os métodos de pagamento */}
      <div className="method-selection">
        <button
          className={`method-button ${selectedMethods.includes("pix") ? "selected" : ""}`}
          onClick={() => handleMethodClick("pix")}
        >
          Pix
        </button>
        <button
          className={`method-button ${selectedMethods.includes("creditCard") ? "selected" : ""}`}
          onClick={() => handleMethodClick("creditCard")}
        >
          Cartão de Crédito
        </button>
        <button
          className={`method-button ${selectedMethods.includes("boleto") ? "selected" : ""}`}
          onClick={() => handleMethodClick("boleto")}
        >
          Boleto
        </button>
      </div>
    </div>
  );
};

export default RetainedCard;
