import React, { useState } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import QuantityOverviewCard from "components/DashboardComponents/QuantityOverviewCard/QuantityOverviewCard";
import SalesStatistics from "components/DashboardComponents/SalesStatistics/SalesStatistics";
import ProductTable from "components/DashboardComponents/ProductTable/ProductTable";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import LineChart from "components/LineChart/LineChart";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import { ReactComponent as CardIcon } from "assets/icons/card-icon.svg";
import { ReactComponent as BoletoIcon } from "assets/icons/boleto-icon.svg";
import "./AdminMerchantDashboard.css";

function AdminMerchantDashboard({ onDateRangeChange }) {
  const [selectedRange, setSelectedRange] = useState(null);

  const handleDateRangeChange = (range) => {
    setSelectedRange(range);
    onDateRangeChange(range);
  };

  const salesData = [
    { icon: <PixIcon />, label: "PIX", value: 0 },
    { icon: <CardIcon />, label: "Cartão de crédito", value: 0 },
    { icon: <BoletoIcon />, label: "Boleto", value: 0 },
  ];

  const products = [
    { id: 1, name: "Camiseta", price: 59.9, quantity: 10 },
    { id: 2, name: "Calça Jeans", price: 149.9, quantity: 5 },
    { id: 3, name: "Tênis", price: 199.99, quantity: 20 },
    { id: 4, name: "Relógio", price: 299.9, quantity: 8 },
    { id: 5, name: "Jaqueta", price: 399.9, quantity: 2 },
    { id: 6, name: "Jaqueta", price: 399.9, quantity: 2 },
    { id: 7, name: "Jaqueta", price: 399.9, quantity: 2 },
    { id: 8, name: "Jaqueta", price: 399.9, quantity: 2 },
  ];

  const salesData1 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const salesData2 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const quantityData1 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const ticket = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const timeLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"]; // Rótulos do tempo

  const hubData = [
    {
      label: "Total de Vendas",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      color: "#4caf50", // Verde para vendas aprovadas
    },
    {
      label: "Saldo líquido",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      color: "#e8bb00", // Amarelo para vendas em análise
    },
  ];

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <div className="dash-content-1">
          <DateRangePicker
            selectedRange={selectedRange}
            setSelectedRange={handleDateRangeChange}
          />
        </div>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Total de Vendas"
                  salesData={salesData1}
                  salesColor="black"
                  totalSoldText="Valor total vendido"
                  salesMethodText="Métodos de pagamento selecionados"
                />
                <SalesOverviewCard
                  title="Saldo Líquido"
                  salesData={salesData2}
                  salesColor="black"
                  totalSoldText="Valor total líquido"
                  salesMethodText="Selecione para ver total líquido por método de pagamento"
                />
              </div>
              <div className="overview-cards-2">
                <QuantityOverviewCard
                  title="Total de Pedidos"
                  salesData={quantityData1}
                  salesColor="black"
                  totalSoldText="Quantidade de pedidos"
                  salesMethodText="Selecione para ver quantidade de pedidos por método de pagamento"
                />
                <SalesOverviewCard
                  title="Ticket Médio"
                  salesData={ticket}
                  salesColor="black"
                  totalSoldText="Ticket Médio"
                  salesMethodText="Selecione para ver ticket por método de pagamento"
                />
              </div>
            </div>

            <div className="charts-section">
              <div className="line-charts1">
                <LineChart
                  title="Histórico de Vendas"
                  timeLabels={timeLabels}
                  hubData={hubData}
                />
              </div>
            </div>
          </div>
          <div className="merchant-grid-2">
            <ProductTable products={products} title="Mais vendidos" />
            <SalesStatistics
              title="Estatísticas de Pagamento"
              salesData={salesData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMerchantDashboard;
