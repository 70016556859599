import React, { useState } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import SalesStatistics from "components/DashboardComponents/SalesStatistics/SalesStatistics";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import LineChart from "components/LineChart/LineChart";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import { ReactComponent as CardIcon } from "assets/icons/card-icon.svg";
import { ReactComponent as BoletoIcon } from "assets/icons/boleto-icon.svg";
import "./AdminDashboard.css";

function AdminDashboard({ onDateRangeChange }) {
  const [selectedRange, setSelectedRange] = useState(null);
  const handleDateRangeChange = (range) => {
    setSelectedRange(range);
    onDateRangeChange(range);
  };

  const salesData = [
    { icon: <PixIcon />, label: "PIX", value: 0 },
    { icon: <CardIcon />, label: "Cartão de crédito", value: 0 },
    { icon: <BoletoIcon />, label: "Boleto", value: 0 },
  ];

  const salesData1 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const salesData2 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const salesData3 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const timeLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"]; // Rótulos do tempo

  const hubData = [
    {
      label: "Vendas Aprovadas",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      color: "#4caf50", // Verde para vendas aprovadas
    },
    {
      label: "Vendas em Análise",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      color: "#ffeb3b", // Amarelo para vendas em análise
    },
    {
      label: "Vendas Reprovadas",
      data: [0, 0, 0, 0, 0, 0, 0, 0],
      color: "#f44336", // Vermelho para vendas reprovadas
    },
  ];

  // Dados simulados para a tabela
  const tableData = [
    { transaction: "TXN123", value: "R$ 1500", status: 1, date: "2024-01-15" },
    { transaction: "TXN124", value: "R$ 2000", status: 2, date: "2024-01-16" },
    { transaction: "TXN125", value: "R$ 750", status: 3, date: "2024-01-17" },
  ];

  const columns = [
    { field: "transaction", header: "Transação" },
    { field: "value", header: "Valor" },
    { field: "date", header: "Data" },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Aprovada";
      case 2:
        return "Em Análise";
      case 3:
        return "Reprovada";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "#4caf50"; // Verde
      case 2:
        return "#ffeb3b"; // Amarelo
      case 3:
        return "#f44336"; // Vermelho
      default:
        return "#6c757d"; // Cinza (Indefinido)
    }
  };

  return (
    <div className="home-dashboard">
      <div className="dash-admin-container">
        <h1 className="dash-title">Dashboard</h1>
        <div className="dash-content-1">
          <DateRangePicker
            selectedRange={selectedRange}
            setSelectedRange={handleDateRangeChange}
          />
        </div>
      </div>

      <div className="admin-grids">
        <div className="admin-content-1">
          <div className="admin-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Vendas Aprovadas"
                  salesData={salesData1}
                  salesColor="black"
                />
                <SalesOverviewCard
                  title="Vendas em Análise"
                  salesData={salesData2}
                  salesColor="black"
                />
                <SalesOverviewCard
                  title="Vendas Reprovadas"
                  salesData={salesData3}
                  salesColor="black"
                />
              </div>
              <div className="charts-section">
                <div className="line-charts">
                  <LineChart
                    title="Histórico de Vendas"
                    timeLabels={timeLabels}
                    hubData={hubData}
                  />
                </div>
              </div>
              {/* Tabela de dados simulados */}
              <div className="table-section">
                <CustomDataTable
                  data={tableData}
                  columns={columns}
                  title="Transações Recentes"
                  showStatusColumn={true}
                  statusField="status"
                  getStatusText={getStatusText}
                  getStatusColor={getStatusColor}
                  searchPlaceholder="Busque por valor, status ou código da transação"
                />
              </div>
            </div>
          </div>
          <div className="admin-grid-2">
            <SalesStatistics title="Vendas Aprovadas" salesData={salesData} />
            <SalesStatistics title="Vendas em análise" salesData={salesData} />
            <SalesStatistics title="Vendas reprovadas" salesData={salesData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
