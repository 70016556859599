import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import './CustomFilter.css';

const CustomFilter = ({ filtersConfig, onFilterChange }) => {
  const [filters, setFilters] = useState({});

  const handleFilterChange = (field, value) => {
    const newFilters = { ...filters, [field]: value };
    setFilters(newFilters);
    onFilterChange(newFilters); // Propaga as mudanças para o pai
  };

  const renderFilter = (filter, index) => {
    switch (filter.type) {
      case 'dropdown':
        return (
          <Dropdown
            key={index}
            value={filters[filter.field] || null}
            options={filter.options}
            onChange={(e) => handleFilterChange(filter.field, e.value)}
            placeholder={filter.placeholder}
            className="filter-dropdown"
          />
        );
      case 'input':
        return (
          <InputText
            key={index}
            value={filters[filter.field] || ''}
            onChange={(e) => handleFilterChange(filter.field, e.target.value)}
            placeholder={filter.placeholder}
            className="filter-input"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="custom-filter">
      {filtersConfig && filtersConfig.map((filter, index) => renderFilter(filter, index))}
    </div>
  );
};

export default CustomFilter;
