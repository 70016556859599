import React from "react";
import CustomInput from "components/CustomInput/CustomInput";
import BasicButton from "components/BasicButton/BasicButton";
// import SvgButton from "components/SvgButton/SvgButton";

const LoginComponent = ({
  email,
  setEmail,
  password,
  setPassword,
  handleLogin,
  loginError,
  errors,
  onSwitchToRegister,
}) => {
  return (
    <div className="login-section">
      <h1>Bem vindo!</h1>
      <p>Insira seu email/usuário e senha para acessar.</p>

      <CustomInput
        label="Email ou usuário"
        type="email"
        value={email}
        placeholder="Email ou usuário"
        onChange={(e) => setEmail(e.target.value)}
        errorMessage={errors.email}
      />

      <CustomInput
        label="Senha"
        type="password"
        value={password}
        placeholder="Sua senha segura muito segura"
        onChange={(e) => setPassword(e.target.value)}
        errorMessage={errors.password}
      />

      <BasicButton
        label="Login"
        icon="pi pi-sign-in" // Ícone de envio do PrimeReact
        onClick={handleLogin}
        className="login-button"
        style={{
          width: '100%',
          margin: '0'
        }}
      />

      {loginError && <p className="error-message">{loginError}</p>}

      {/* <div className="divider">
        <span>ou</span>
      </div> */}

      {/* <div className="sign-in-buttons">
        <SvgButton
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-google"
              viewBox="0 0 16 16"
            >
              <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
            </svg>
          }
          label="Faça login com Google"
          backgroundColor="#ffffff"
          textColor="#000000"
          borderColor="#dadce0"
          style={{ width: "100%", padding: "12px 24px" }}
          onClick={() => console.log("Google Sign In clicked")}
        />

        <SvgButton
          svgIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-apple"
              viewBox="0 0 16 16"
            >
              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
            </svg>
          }
          label="Faça login com Apple ID"
          backgroundColor="#ffffff"
          textColor="#000000"
          borderColor="#dadce0"
          style={{ width: "100%", padding: "12px 24px" }}
          onClick={() => console.log("Apple Sign In clicked")}
        />
      </div> */}

      {/* Botão de Cadastre-se */}
      <p>
        Não tem uma conta?{" "}
        <span onClick={onSwitchToRegister} className="register-link">
          Cadastre-se
        </span>
      </p>
    </div>
  );
};

export default LoginComponent;
