import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Para redirecionamento
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import AdminDashboard from "components/DashboardComponents/AdminDashboard/AdminDashboard";
import AdminTransactions from "components/DashboardComponents/AdminTransactions/AdminTransactions";
import AdminTransfers from "components/DashboardComponents/AdminTransfers/AdminTransfers";
import AdminRequests from "components/DashboardComponents/AdminRequests/AdminRequests";
import MerchantApproved from "components/DashboardComponents/MerchantStatus/MerchantApproved/MerchantApproved";
import MerchantAnalysis from "components/DashboardComponents/MerchantStatus/MerchantAnalysis/MerchantAnalysis";
import MerchantReproved from "components/DashboardComponents/MerchantStatus/MerchantReproved/MerchantReproved";
import { logout } from "services/authService"; // Função de logout do authService
import "./AdminPanel.css";

function AdminPanel() {
  const [activeComponent, setActiveComponent] = useState("home");
  const [activeIcon, setActiveIcon] = useState(0);
  const navigate = useNavigate(); // Hook para navegação

  // Função de logout com depuração
  const handleLogout = async () => {
    console.log("Logout iniciado"); // Depuração
    try {
      await logout();
      console.log("Logout concluído, redirecionando..."); // Depuração
      navigate("/login"); // Redireciona para a página de login após o logout
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const firstSideBarIcons = [
    { iconClass: "pi-home", hasSubmenu: false },
    { iconClass: "pi-users", hasSubmenu: true },
    { iconClass: "pi-dollar", hasSubmenu: false },
    { iconClass: "pi-arrow-right-arrow-left", hasSubmenu: true },
  ];

  const secondSideBarMenus = [
    [],
    ["Aprovados", "Solicitações", "Reprovados"],
    [],
    ["Solicitados", "Histórico"],
  ];

  // Ícone de logout na TopBar com a função de logout
  const topBarIcons = [
    { iconClass: "pi-sign-out", action: handleLogout }, // Ícone de logout com função de logout
  ];

  const handleIconClick = (iconIndex, hasSubmenu) => {
    setActiveIcon(iconIndex);

    if (!hasSubmenu) {
      if (iconIndex === 0) {
        setActiveComponent("home");
      } else if (iconIndex === 2) {
        setActiveComponent("transactions");
      } else if (iconIndex === 3) {
        setActiveComponent("transfers");
      }
    }
  };

  const handleSubmenuClick = (submenuItem) => {
    if (submenuItem === "Aprovados") {
      setActiveComponent("approved");
    } else if (submenuItem === "Solicitações") {
      setActiveComponent("analysis");
    } else if (submenuItem === "Reprovados") {
      setActiveComponent("reproved");
    } else if (submenuItem === "Solicitados") {
      setActiveComponent("requested");
    } else if (submenuItem === "Histórico") {
      setActiveComponent("record");
    }
  };

  // Função para lidar com o clique no ícone da TopBar (apenas logout)
  const handleTopBarIconClick = (icon) => {
    console.log("Ícone da TopBar clicado:", icon); // Depuração
    if (icon && icon.action) {
      icon.action(); // Executa a função de logout
    }
  };

  return (
    <div className="admin-content">
      <DashboardLayout
        firstSideBarIcons={firstSideBarIcons}
        secondSideBarMenus={secondSideBarMenus}
        onIconClick={handleIconClick}
        onSubmenuClick={handleSubmenuClick}
        activeIcon={activeIcon}
        topBarIcons={topBarIcons} // Passa o ícone de logout
        onTopBarIconClick={handleTopBarIconClick} // Função para cliques no ícone de logout
      >
        {activeComponent === "home" && <AdminDashboard />}
        {activeComponent === "approved" && <MerchantApproved />}
        {activeComponent === "analysis" && <MerchantAnalysis />}
        {activeComponent === "reproved" && <MerchantReproved />}
        {activeComponent === "transactions" && <AdminTransactions />}
        {activeComponent === "requested" && <AdminRequests />}
        {activeComponent === "record" && <AdminTransfers />}
        {activeComponent === "settings" && <div>Configurações</div>}
      </DashboardLayout>
    </div>
  );
}

export default AdminPanel;
