import React from "react";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import "./MerchantTransactions.css";

const MerchantTransactions = () => {
  const data = [
    {
      Transacao: "273618782",
      Client: "Joãozinho Picafina",
      Value: "R$3340",
      Status: "Aprovada",
    },
  ];

  // Configurações das colunas da tabela
  const columns = [
    { field: "Transacao", header: "Transação" },
    { field: "Client", header: "Cliente" },
    { field: "Value", header: "Valor" },
    { field: "Status", header: "Status" },
  ];

  // Função chamada ao clicar em "Ver mais"
  const handleInfoButtonClick = (rowData) => {
    alert(`Ver mais sobre: ${rowData.Seller}`);
  };

  return (
    <div>
      <h1 className="dash-title">Histórico de vendas</h1>
      <div className="table-section">
        <CustomDataTable
          data={data}
          columns={columns}
          title="Todas as vendas"
          showInfoColumn={true}
          infoButtonTitle="Ver mais"
          onInfoButtonClick={handleInfoButtonClick}
          searchPlaceholder="Busque por valor ou cliente"
        />
      </div>
    </div>
  );
};

export default MerchantTransactions;
