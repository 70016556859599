import React, { useState } from "react";
import SideBar from "components/SideBar/SideBar";
import TopBar from "components/TopBar/TopBar";
import './DashboardLayout.css';

const DashboardLayout = ({
  firstSideBarIcons,
  secondSideBarMenus,
  onIconClick,
  activeIcon,
  onSubmenuClick,
  topBarIcons, // Recebe os ícones da TopBar
  onTopBarIconClick, // Função para lidar com cliques nos ícones da TopBar
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSecondSidebarOpen, setIsSecondSidebarOpen] = useState(false); // Estado para a SecondSideBar

  const toggleSideBar = () => {
    setIsExpanded(!isExpanded);
    if (isSecondSidebarOpen) {
      setIsSecondSidebarOpen(false); // Fecha a SecondSideBar se ela estiver aberta
    }
  };

  return (
    <div className="dashboard-layout">
      {/* Passa os ícones da TopBar e a função de clique */}
      <TopBar
        toggleSideBar={toggleSideBar}
        topBarIcons={topBarIcons}
        onTopBarIconClick={onTopBarIconClick}
      />

      <div className="dashboard-content">
        {/* Sidebar expandida ou colapsada */}
        <div className={`sidebars-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
          <SideBar
            firstSideBarIcons={firstSideBarIcons}
            secondSideBarMenus={secondSideBarMenus}
            isExpanded={isExpanded}
            onIconClick={onIconClick}
            activeIcon={activeIcon}
            onSubmenuClick={onSubmenuClick}
          />
        </div>

        {/* O conteúdo dinâmico é passado como children */}
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
