import React, { useState } from "react";
import './TaxCard.css'; // Arquivo de estilos para o componente

const TaxCard = ({
  title, // Título do card (ex: "Cartão de crédito")
  description, // Descrição do card (ex: "Receba pagamentos...")
  mainRate, // Taxa principal (ex: "6.99% + R$ 2,99 / transação (à vista)")
  reserve, // Reserva financeira (ex: "Reserva financeira de 20%")
  icon, // SVG ou ícone no canto superior direito
  accordionTitle, // Título do accordion (ex: "Ver taxas parceladas")
  installmentRates, // Taxas parceladas (ex: ["2x - 6x: 22.99% + R$ 2,99", "7x - 12x: 29.99% + R$ 2,99"])
  showAccordion, // Controle se o accordion será exibido
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="tax-card">
      <div className="tax-card-header">
        <h2 className="tax-card-title">{title}</h2>
        <div className="tax-card-icon">{icon}</div> {/* Ícone editável */}
      </div>
      <p className="tax-card-description">{description}</p>
      <h3 className="tax-card-rate">{mainRate}</h3>
      <p className="tax-card-reserve">{reserve}</p>

      {showAccordion && (
        <div className="tax-card-accordion">
          <button className="accordion-toggle" onClick={toggleAccordion}>
            {accordionTitle}
          </button>
          {isAccordionOpen && (
            <div className="accordion-content">
              <ul>
                {installmentRates.map((rate, index) => (
                  <li key={index}>{rate}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaxCard;
