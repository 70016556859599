import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Incluindo useParams para pegar o userId
import BasicButton from "components/BasicButton/BasicButton";
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import AdminMerchantDashboard from "components/AdminControl/AdminMerchantDashboard/AdminMerchantDashboard";
import AdminMerchantTransactions from "components/AdminControl/AdminMerchantTransactions/AdminMerchantTransactions";
import AdminMerchantLeads from "components/AdminControl/AdminMerchantLeads/AdminMerchantLeads";
import AdminMerchantBalance from "components/AdminControl/AdminMerchantBalance/AdminMerchantBalance";
import AdminMerchantTax from "components/AdminControl/AdminMerchantTax/AdminMerchantTax";
import AdminMerchantDetails from "components/AdminControl/AdminMerchantDetails/AdminMerchantDetails";
import { getUserKycData } from "services/authService"; // Importando o serviço para buscar os dados do KYC
import "./MerchantDetails.css";

function MerchantDetails() {
  const [activeComponent, setActiveComponent] = useState("details");
  const [activeIcon, setActiveIcon] = useState(0); // Atualiza o estado inicial aqui
  const [merchantName, setMerchantName] = useState(""); // Estado para armazenar o nome do Merchant
  const { userId } = useParams(); // Pegando o userId da URL
  const navigate = useNavigate(); // Hook do react-router-dom para navegação

  // Função para buscar o nome do merchant
  useEffect(() => {
    const fetchMerchantData = async () => {
      try {
        const data = await getUserKycData(userId); // Chamando o serviço
        setMerchantName(data.fullName); // Salvando o nome do merchant
      } catch (error) {
        console.error("Erro ao buscar os dados do merchant:", error);
      }
    };

    if (userId) {
      fetchMerchantData();
    }
  }, [userId]);

  const firstSideBarIcons = [
    { iconClass: "pi-user", hasSubmenu: false },
    { iconClass: "pi-home", hasSubmenu: false },
    { iconClass: "pi-shopping-bag", hasSubmenu: false },
    { iconClass: "pi-money-bill", hasSubmenu: false },
  ];

  const secondSideBarMenus = [
    [], // Sem submenu para Home
    [], // Sem submenu para Shopping
    [], // Sem submenu para User
    [], // Submenu para Money Bill
  ];

  // Ícones da TopBar com seus respectivos componentes
  const topBarIcons = [
  ];

  const handleIconClick = (iconIndex, hasSubmenu) => {
    setActiveIcon(iconIndex); // Atualiza o ícone ativo

    if (!hasSubmenu) {
      if (iconIndex === 0) {
        setActiveComponent("details");
      } else if (iconIndex === 1) {
        setActiveComponent("home");
      } else if (iconIndex === 2) {
        setActiveComponent("sales");
      } else if (iconIndex === 3) {
        setActiveComponent("transfers");
      }
    } else {
      if (iconIndex === 3) {
        setActiveComponent("moneyBillSubmenu");
      }
    }
  };

  // Função para lidar com cliques nos ícones da TopBar
  const handleTopBarIconClick = (component) => {
    setActiveComponent(component); // Define o componente ativo com base no ícone clicado
  };

  // Função para realizar o logout e redirecionar para a página /admin
  const handleLogoutMerchant = () => {
    if (window.confirm("Você realmente deseja deslogar?")) {
      navigate("/admin"); // Redireciona para a página de admin após o logout
    }
  };

  return (
    <div>
      <div className="merchant-content">
        <DashboardLayout
          firstSideBarIcons={firstSideBarIcons}
          secondSideBarMenus={secondSideBarMenus}
          onIconClick={handleIconClick}
          activeIcon={activeIcon}
          topBarIcons={topBarIcons}
          onTopBarIconClick={handleTopBarIconClick}
        >
          <div className="dash-container">
            <h1 className="dash-title">
              {/* Exibe o nome do Merchant */}
              Você está logado como {merchantName || "Carregando..."}
            </h1>
            <BasicButton
              icon="pi pi-sign-out"
              label="Deslogar"
              onClick={handleLogoutMerchant}
              className="login-button"
              style={{
                margin: "2px",
                height: "40px",
              }}
            />
          </div>

          {/* Renderização condicional baseada no estado activeComponent */}
          {activeComponent === "home" && <AdminMerchantDashboard />}
          {activeComponent === "sales" && <AdminMerchantTransactions />}
          {activeComponent === "leads" && <AdminMerchantLeads />}
          {activeComponent === "transfers" && <AdminMerchantBalance />}
          {activeComponent === "tax" && <AdminMerchantTax />}
          {activeComponent === "details" && <AdminMerchantDetails />}
          {activeComponent === "settings" && <div>Configurações</div>}
        </DashboardLayout>
      </div>
    </div>
  );
}

export default MerchantDetails;
