import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { Button } from 'primereact/button'; // Importa o botão do PrimeReact
import 'react-day-picker/dist/style.css';
import './DayPicker.css';

const DateRangePicker = ({ selectedRange, setSelectedRange }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // Controla o dropdown

  const predefinidos = [
    { label: 'Hoje', value: 1 },
    { label: 'Ontem', value: 2 },
    { label: '7 dias', value: 7 },
    { label: '15 dias', value: 15 },
    { label: '30 dias', value: 30 }
  ];

  const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat('pt-BR').format(date); // Formato dd/mm/yyyy
  };

  const handlePredefinidoClick = (days) => {
    const hoje = new Date();
    const startDate = new Date();
    startDate.setDate(hoje.getDate() - days);

    setSelectedRange({
      from: startDate,
      to: hoje,
    });
    setShowDropdown(false); // Fecha o dropdown ao selecionar
  };

  const handleCustomRangeClick = () => {
    setShowCalendar(true);
    setShowDropdown(false); // Fecha o dropdown ao selecionar "Período personalizado"
  };

  const handleDateSelect = (range) => {
    setSelectedRange(range); // Atualiza o intervalo de datas
  };

  const handleApply = () => {
    setShowCalendar(false);
  };

  const handleClear = () => {
    setSelectedRange(null);
    setShowCalendar(false);
  };

  return (
    <div className="date-picker-container" style={{ display: 'flex' }}>
      {/* Botão com ícone de calendário e exibição do intervalo selecionado */}
      <Button
        icon="pi pi-calendar" // Ícone de calendário do PrimeReact
        className="p-button-text p-mr-2" // Classe de botão de texto e margem à direita
        onClick={() => setShowDropdown(!showDropdown)}
        style={{ 
          backgroundColor: '#FF6600', color: '#fff',
          borderRadius: '0.25rem 0 0 0.25rem',
          padding: '0.6rem'
         }} // Estilo do botão laranja
      />
      <div
        className="selected-range"
        onClick={() => setShowDropdown(!showDropdown)}
        style={{
          border: '1px solid #ccc',
          padding: '0.6rem',
          borderRadius: '0  0.25rem  0.25rem 0',
          whiteSpace: 'nowrap',  // Impede quebra de linha
          overflow: 'hidden',
          textOverflow: 'ellipsis',  // Adiciona reticências se o texto for muito longo
          minWidth: '160px',  // Define uma largura mínima
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {selectedRange && selectedRange.from && selectedRange.to
          ? `${formatDate(selectedRange.from)} - ${formatDate(selectedRange.to)}`
          : 'Período'}
      </div>

      {/* Exibe o dropdown apenas se showDropdown for true */}
      {showDropdown && !showCalendar && (
        <div className="dropdown-menu">
          {predefinidos.map((item) => (
            <div
              key={item.value}
              className="dropdown-item"
              onClick={() => handlePredefinidoClick(item.value)}
            >
              {item.label}
            </div>
          ))}
          <div className="dropdown-item" onClick={handleCustomRangeClick}>
            Período personalizado
          </div>
        </div>
      )}

      {/* Exibe o calendário apenas se showCalendar for true */}
      {showCalendar && (
        <div className="calendar-container">
          <DayPicker
            mode="range"
            selected={selectedRange}
            onSelect={handleDateSelect}
            numberOfMonths={2}
            timeZone="America/Sao_Paulo" 
          />
          <div className="calendar-footer">
            <button className="clear-button" onClick={handleClear}>Limpar</button>
            <button className="apply-button" onClick={handleApply}>Aplicar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
