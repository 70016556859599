import React from 'react';
import './SalesStatistics.css'; // CSS acima

const SalesStatistics = ({ salesData, title = "Estatísticas de vendas" }) => {
  const maxValue = Math.max(...salesData.map(item => item.value));

  return (
    <div className="sales-statistics">
      <h2>{title}</h2> {/* Título editável via props */}
      {salesData.map((item, index) => (
        <div key={index} className="sales-item">
          <div className="icon">{item.icon}</div> {/* Exibe o SVG passado */}
          <div className="info">
            <span className="label">{item.label}</span>
            <span className="value">R$ {item.value.toFixed(2)}</span>
          </div>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${(item.value / maxValue) * 100}%` }} // Calculando a largura da barra
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SalesStatistics;
