import React, { useState } from "react";
import "primeicons/primeicons.css";
import "./SideBar.css"; // Arquivo CSS

// Componente FirstSideBar
const FirstSideBar = ({ icons, onIconClick, currentActive, isExpanded }) => {
  return (
    <div className={`first-sidebar ${isExpanded ? "expanded" : "collapsed"}`}>
      <ul className="icon-list">
        {icons.map((icon, index) => (
          <li
            key={index}
            className={currentActive === index ? "active" : ""}
            onClick={() => onIconClick(index, icon.hasSubmenu)}
          >
            <i className={`pi ${icon.iconClass}`} />
          </li>
        ))}
      </ul>
    </div>
  );
};

// Componente SecondSideBar com navegação
const SecondSideBar = ({
  submenuItems,
  onSubmenuClick,
  customStyles,
  customClass,
  isVisible,
}) => (
  <div
    className={`second-sidebar ${isVisible ? "open" : ""} ${customClass}`}
    style={customStyles}
  >
    <ul className="submenu-list">
      {submenuItems.map((item, index) => (
        <li key={index}>
          <button onClick={() => onSubmenuClick(item)} className="submenu-button">
            {item}
          </button>
        </li>
      ))}
    </ul>
  </div>
);

const SideBar = ({
  firstSideBarIcons,
  secondSideBarMenus,
  secondSideBarStyles,
  secondSideBarClass,
  isExpanded,
  onIconClick, // Função para a navegação principal
  onSubmenuClick, // Função para a navegação nos submenus
  activeIcon, // Agora recebemos o activeIcon de fora
}) => {
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [submenuItems, setSubmenuItems] = useState([]);

  // Função para gerenciar o clique nos ícones da FirstSideBar
  const handleIconClick = (iconIndex, hasSubmenu) => {
    console.log("Ícone clicado:", iconIndex); // Log para depuração

    if (hasSubmenu) {
      // Exibe ou esconde o submenu quando o ícone tem submenu
      if (activeIcon === iconIndex && submenuVisible) {
        setSubmenuVisible(false); // Fecha o submenu se for o mesmo ícone
      } else {
        setSubmenuItems(secondSideBarMenus[iconIndex] || []);
        setSubmenuVisible(true); // Abre o submenu se outro ícone for clicado
      }
    } else {
      setSubmenuVisible(false); // Fecha o submenu se o ícone não tiver submenu
    }

    onIconClick(iconIndex, hasSubmenu); // Chama a função de navegação do pai
  };

  return (
    <div className="sidebars-container">
      <FirstSideBar
        icons={firstSideBarIcons}
        onIconClick={handleIconClick}
        currentActive={activeIcon} // Passa o ícone ativo
        isExpanded={isExpanded}
      />

      {/* Renderiza a SecondSideBar se ela estiver visível */}
      {submenuVisible && isExpanded && (
        <SecondSideBar
          submenuItems={submenuItems}
          customStyles={secondSideBarStyles}
          customClass={`${submenuVisible ? "open" : "closed"} ${secondSideBarClass}`}
          onSubmenuClick={onSubmenuClick} // Função para navegação no submenu
        />
      )}
    </div>
  );
};

export default SideBar;
