import React from "react";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import "./AdminRequests.css";

const AdminRequests = () => {
  const data = [
    {
      Transfers: "273618782",
      Seller: "Joãozinho Picafina",
      Value: "R$78000",
      Status: "Aprovada",
    },
  ];

  const columns = [
    { field: "Transfers", header: "Transferências" },
    { field: "Seller", header: "Seller" },
    { field: "Value", header: "Valor" },
    { field: "Status", header: "Status" },
  ];

  // Função chamada ao clicar em "Ver mais"
  const handleInfoButtonClick = (rowData) => {
    alert(`Ver mais sobre: ${rowData.Seller}`);
  };

  return (
    <div>
      <h1 className="dash-title">Solicitações de saque</h1>
      <div className="table-section">
        <CustomDataTable
          data={data}
          columns={columns}
          title="Transferências"
          showInfoColumn={true}
          infoButtonTitle="Ver mais"
          onInfoButtonClick={handleInfoButtonClick}
        />
      </div>
    </div>
  );
};

export default AdminRequests;
