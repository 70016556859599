import React, { useEffect, useState } from "react";
import TaxCard from "components/DashboardComponents/TaxCard/TaxCard";
import RetainedCard from "components/DashboardComponents/RetainedCard/RetainedCard";
import { getMerchantFees } from "services/authService"; // Função para puxar taxas do backend
import "./MerchantTax.css"; // Arquivo CSS para o componente
import { ReactComponent as CreditCardIcon } from "assets/icons/card-icon.svg";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import { ReactComponent as BoletoIcon } from "assets/icons/boleto-icon.svg";

const MerchantTax = ({ userId }) => { // userId já vem das props, não precisa redeclarar
  const [fees, setFees] = useState(null);
  const [loading, setLoading] = useState(true);
  const retainedData = {
    pix: 0, // Exemplo de valor retido por Pix
    creditCard: 0, // Exemplo de valor retido por Cartão de Crédito
    boleto: 0, // Exemplo de valor retido por Boleto
  };

  useEffect(() => {
    const fetchFees = async () => {
      try {
        if (!userId) {
          console.error("userId não está definido");
          return; // Evita continuar se não houver userId
        }
        const fetchedFees = await getMerchantFees(userId); // Puxa as taxas do backend
        setFees(fetchedFees);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao carregar taxas do Merchant:", error);
        setLoading(false);
      }
    };

    fetchFees(); // Chama a função após verificar que o userId está definido
  }, [userId]);

  if (loading) return <div>Carregando taxas...</div>;

  return (
    <div className="merchant-tax-container">
      <h1 className="dash-title">Minhas taxas</h1>
      <div className="tax-content">
        {/* Cartão de taxas para Pix */}
        <div className="tax-item">
          <TaxCard
            title="Pix"
            description="Receba pagamentos instantâneos via Pix."
            mainRate={`${fees.feePix}%`}
            reserve={`Reserva financeira de ${fees.reservePix}%`}
            icon={<PixIcon />} // Ícone SVG para Pix
          />
        </div>

        {/* Cartão de taxas para Boleto */}
        <div className="tax-item">
          <TaxCard
            title="Boleto"
            description="Receba pagamentos via boleto bancário."
            mainRate={`${fees.feeTED}%`} // Assumindo que feeTED representa boleto aqui
            reserve={`Reserva financeira de ${fees.reserveTED}%`}
            icon={<BoletoIcon />} // Ícone SVG para Boleto
          />
        </div>

        {/* Cartão de taxas para Cartão de Crédito */}
        <div className="tax-item">
          <TaxCard
            title="Cartão de Crédito"
            description={`Receba pagamentos com cartão de crédito em ${fees.creditReleaseDays} dias.`}
            mainRate={`${fees.feeCredit}%`}
            reserve={`Reserva financeira de ${fees.reserveCredit}%`}
            icon={<CreditCardIcon />} // Ícone SVG para Cartão de Crédito
          />
        </div>

        {/* Cartão de saldo retido */}
        <div className="tax-item">
          <RetainedCard
            title="Reserva Financeira"
            description="Verifique sua reserva financeira para cada tipo de transação"
            retainedData={retainedData} // Valores de saldo retido por método
            additionalInfo="Selecione os métodos para visualizar o saldo retido"
          />
        </div>
      </div>
    </div>
  );
};

export default MerchantTax;
