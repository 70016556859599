import React, { useState, useEffect } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { formatCPF, formatCNPJ, formatPhone } from "utils/formatters";
import { registerUser } from "services/authService";
import { validateFullName, validateEmail } from "utils/validators";
import CustomDialog from "components/CustomDialog/CustomDialog";

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Senha é obrigatória")
    .min(8, "Senha deve ter no mínimo 8 caracteres")
    .matches(/[A-Z]/, "Senha deve conter ao menos uma letra maiúscula")
    .matches(/[a-z]/, "Senha deve conter ao menos uma letra minúscula")
    .matches(/[0-9]/, "Senha deve conter ao menos um número")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Senha deve conter ao menos um caractere especial"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem coincidir")
    .required("Confirmação de senha é obrigatória"),
});

const RegisterComponent = ({ onSwitchToLogin }) => {
  const [formValues, setFormValues] = useState({
    username: "",
    fullName: "",
    email: "",
    confirmEmail: "",
    cpfCnpj: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [isJuridica, setIsJuridica] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [registrationSuccess, setRegistrationSuccess] = useState(null);
  const [registrationError, setRegistrationError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChangeWithFormatting = (e) => {
    const { name, value } = e.target;

    if (name === "cpfCnpj") {
      const formattedValue = isJuridica ? formatCNPJ(value) : formatCPF(value);
      setFormValues({ ...formValues, [name]: formattedValue });
    } else if (name === "phoneNumber") {
      const formattedPhone = formatPhone(value);
      setFormValues({ ...formValues, [name]: formattedPhone });
    } else {
      handleInputChange(e);
    }
  };

  const handleToggleChange = (tipoPessoa) => {
    setFormValues({
      username: "",
      fullName: "",
      email: "",
      confirmEmail: "",
      cpfCnpj: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    });
    setIsJuridica(tipoPessoa === "PJ");
    setCurrentStep(1);
  };

  const handlePasswordChange = async (e) => {
    const { value } = e.target;
    setFormValues({ ...formValues, password: value });

    try {
      await passwordSchema.validate({ password: value }, { abortEarly: false });
      setPasswordErrors([]); // Limpa os erros se a senha for válida
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setPasswordErrors(error.errors); // Atualiza os erros conforme a validação
      }
    }
  };

  const handleRegisterSubmit = async () => {
    setIsSubmitting(true);
    try {
      await passwordSchema.validate(
        {
          password: formValues.password,
          confirmPassword: formValues.confirmPassword,
        },
        { abortEarly: false }
      );

      const fullNameError = validateFullName(formValues.fullName, isJuridica);
      const emailError = validateEmail(formValues.email);

      if (fullNameError) {
        setRegistrationError(fullNameError);
        setCurrentStep(1);
        return;
      }

      if (emailError) {
        setRegistrationError(emailError);
        setCurrentStep(1);
        return;
      }

      if (formValues.email !== formValues.confirmEmail) {
        setRegistrationError("Os emails não coincidem.");
        setCurrentStep(1);
        return;
      }

      // Remover caracteres especiais de CpfCnpj e PhoneNumber
      const sanitizedCpfCnpj = formValues.cpfCnpj.replace(/[^\d]/g, "");
      const sanitizedPhoneNumber = formValues.phoneNumber.replace(/[^\d]/g, "");

      const userData = {
        username: formValues.username,
        fullName: formValues.fullName,
        email: formValues.email,
        password: formValues.password,
        cpfCnpj: sanitizedCpfCnpj,
        phoneNumber: sanitizedPhoneNumber,
        role: "Merchant",
        enTypeMerchant: isJuridica ? "PJ" : "PF",
      };

      console.log(userData)

      await registerUser(userData);

      setRegistrationSuccess("Cadastro enviado para análise.");
      setRegistrationError(null);
      setShowDialog(true);

      setTimeout(() => {
        navigate("/login");
      }, 8000);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setRegistrationError(error.errors.join(", "));
      } else {
        setRegistrationError(
          "Ocorreu um erro durante o cadastro. Tente novamente."
        );
      }
      setRegistrationSuccess(null);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showDialog) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showDialog, navigate]);


  return (
    <div className="login-section">
      <div className="form-section">
        <h2>Crie sua conta</h2>

        {/* Toggle para Física e Jurídica */}
        <div className="toggle-section">
          <Button
            label="Pessoa Física"
            className={`type-person-button ${!isJuridica ? "selected" : ""}`}
            onClick={() => handleToggleChange("PF")}
            style={{
              marginRight: '1rem',
              marginBottom: '2rem'
            }}
          />
          <Button
            label="Pessoa Jurídica"
            className={`type-person-button ${isJuridica ? "selected" : ""}`}
            onClick={() => handleToggleChange("PJ")}
            style={{
              marginRight: '1rem',
              marginBottom: '2rem'
            }}
          />
        </div>

        <div className="register-form">
          {currentStep === 1 && (
            <>
              <CustomInput
                label="Usuário"
                type="text"
                name="username"
                value={formValues.username}
                placeholder="Digite o seu usuário"
                onChange={handleInputChange}
              />
              <CustomInput
                label={isJuridica ? "Razão Social" : "Nome Completo"}
                type="text"
                name="fullName"
                value={formValues.fullName}
                placeholder={
                  isJuridica
                    ? "Digite a razão social da sua empresa"
                    : "Digite o seu nome completo"
                }
                onChange={handleInputChange}
              />
              <CustomInput
                label="Endereço de e-mail"
                type="email"
                name="email"
                value={formValues.email}
                placeholder="Digite o seu e-mail"
                onChange={handleInputChange}
              />
              <CustomInput
                label="Confirme o endereço de e-mail"
                type="email"
                name="confirmEmail"
                value={formValues.confirmEmail}
                placeholder="Confirme o seu e-mail"
                onChange={handleInputChange}
              />
              <Button
                label="Próximo"
                onClick={() => setCurrentStep(2)}
                className="next-button"
              />
            </>
          )}

          {currentStep === 2 && (
            <>
              <CustomInput
                label={isJuridica ? "CNPJ" : "CPF"}
                type="text"
                name="cpfCnpj"
                value={formValues.cpfCnpj}
                placeholder={isJuridica ? "Digite seu CNPJ" : "Digite seu CPF"}
                onChange={handleInputChangeWithFormatting}
              />
              <CustomInput
                label="Telefone"
                type="text"
                name="phoneNumber"
                value={formValues.phoneNumber}
                placeholder="Número para contato"
                onChange={handleInputChangeWithFormatting}
              />
              <CustomInput
                label="Crie sua senha"
                type="password"
                name="password"
                value={formValues.password}
                placeholder="Digite sua senha"
                onChange={handlePasswordChange}
              />
              <CustomInput
                label="Confirme sua senha"
                type="password"
                name="confirmPassword"
                value={formValues.confirmPassword}
                placeholder="Repita a senha"
                onChange={handleInputChange}
              />
              {passwordErrors.length > 0 && (
                <ul className="password-errors">
                  {passwordErrors.map((error, index) => (
                    <li key={index} className="error-text">
                      {error}
                    </li>
                  ))}
                </ul>
              )}

              <div className="buttons">
                <Button
                  label={isSubmitting ? "Cadastrando..." : "Cadastrar"}
                  icon={isSubmitting ? "pi pi-spin pi-spinner" : "pi pi-check"}
                  onClick={handleRegisterSubmit}
                  className={`register-button ${
                    isSubmitting ? "register-button-disabled" : ""
                  }`}
                  disabled={isSubmitting}
                  style={{marginRight: '5px'}}
                />
                <Button
                  label="Voltar"
                  onClick={() => setCurrentStep(1)}
                  className="back-button"
                />
              </div>
            </>
          )}

          {registrationSuccess && (
            <p className="success-message">{registrationSuccess}</p>
          )}
          {registrationError && (
            <p className="error-message">{registrationError}</p>
          )}

          <p className="login-link">
            Já possui uma conta? <a href="/login">Faça login</a>
          </p>
        </div>
      </div>

      <CustomDialog
        visible={showDialog}
        header="Cadastro concluído"
        message="Por favor, faça login em nossa plataforma para etapa de KYC"
        onHide={() => {
          setShowDialog(false);
          window.location.reload();
        }}
      />
    </div>
  );
};

export default RegisterComponent;
