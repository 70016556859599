import React from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import QuantityOverviewCard from "components/DashboardComponents/QuantityOverviewCard/QuantityOverviewCard";
import BalanceStatistics from "components/DashboardComponents/BalanceStatistics/BalanceStatistics";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import { ReactComponent as Chargeback } from "assets/icons/cancel.svg";
import { ReactComponent as Reembolsado } from "assets/icons/arrow.svg";
import "./AdminMerchantBalance.css";

function AdminMerchantBalance() {

  const data = [
    {
      Value: "273618782",
      Date: "Joãozinho Picafina",
      Status: "R$3340",
    },
  ];

  // Função chamada ao clicar em "Ver mais"
  const handleInfoButtonClick = (rowData) => {
    alert(`Ver mais sobre: ${rowData.Seller}`);
  };

  // Configurações das colunas da tabela
  const columns = [
    { field: "Value", header: "Valor" },
    { field: "Date", header: "Data" },
    { field: "Status", header: "Status" },
  ];

  const salesData = [
    { icon: <Chargeback />, label: "Chargeback", value: 540.3 },
    { icon: <Reembolsado />, label: "Reembolsos", value: 10.5 },
    { icon: <Chargeback />, label: "Cancelado", value: 20.1 },
  ];

  const salesData1 = {
    pix: 15000,
    creditCard: 22220000,
    boleto: 8000,
  };

  const salesData2 = {
    pix: 1845,
    creditCard: 13927,
    boleto: 2673,
  };

  const quantityData1 = {
    pix: 15,
    creditCard: 127,
    boleto: 3,
  };

  const ticket = {
    pix: 1544,
    creditCard: 1137,
    boleto: 273,
  };

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <h1 className="dash-title">Transferências</h1>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={salesData1}
                  salesColor="black"
                  totalSoldText="Valor disponível"
                  salesMethodText="Métodos de pagamento selecionados"
                />
                <SalesOverviewCard
                  title="Saldo Pendente"
                  salesData={salesData2}
                  salesColor="black"
                  totalSoldText="Valor Pendente"
                  salesMethodText="Selecione para ver total líquido por método de pagamento"
                />
              </div>
              <div className="overview-cards-2">
                <QuantityOverviewCard
                  title="Total de Pedidos"
                  salesData={quantityData1}
                  salesColor="black"
                  totalSoldText="Quantidade de pedidos"
                  salesMethodText="Selecione para ver quantidade de pedidos por método de pagamento"
                />
                <SalesOverviewCard
                  title="Ticket Médio"
                  salesData={ticket}
                  salesColor="black"
                  totalSoldText="Ticket Médio"
                  salesMethodText="Selecione para ver ticket por método de pagamento"
                />
              </div>
            </div>

            <div className="table-section">
              <CustomDataTable
                data={data}
                columns={columns}
                title="Historícos de saques"
                showInfoColumn={true}
                infoButtonTitle="Ver mais"
                onInfoButtonClick={handleInfoButtonClick}
                searchPlaceholder="Busque por saldo ou status"
              />
            </div>
          </div>
          <div className="merchant-grid-2">
            <BalanceStatistics
              title="Estatísticas de Saque"
              salesData={salesData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminMerchantBalance;
