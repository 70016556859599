import React, { useState } from "react";
import Modal from "react-modal";
import { SelectButton } from "primereact/selectbutton";
import "./ModalBalance.css"; // Para estilos

Modal.setAppElement("#root");

const ModalBalance = ({ isOpen, closeModal, saldoPix, saldoCartao }) => {
  const options = ["Pix/Boleto", "Cartão de Crédito"];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [valorSaque, setValorSaque] = useState("R$ 0,00");

  // Função para formatar o valor como moeda brasileira
  const formatToBRL = (value) => {
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value || 0);
    return formattedValue;
  };

  // Lida com a formatação de valor e atualização do estado
  const handleInputChange = (e) => {
    let value = e.target.value;

    // Remove qualquer caractere que não seja dígito
    value = value.replace(/\D/g, "");

    // Divide por 100 para converter em formato decimal (ex: 999999 => 9999,99)
    const floatValue = parseFloat(value) / 100;

    // Atualiza o estado com o valor formatado
    setValorSaque(formatToBRL(floatValue));
  };

  // Reseta o valor do saque quando o usuário muda a opção (Pix/Boleto ou Cartão)
  const handleOptionChange = (e) => {
    setSelectedOption(e.value);
    setValorSaque("R$ 0,00"); // Reseta o valor para R$ 0,00 ao trocar a opção
  };

  const handleSaque = () => {
    const tipoSaque =
      selectedOption === "Pix/Boleto" ? "Pix/Boleto" : "Cartão de Crédito";
    alert(`Saque de ${valorSaque} solicitado via ${tipoSaque}`);
    closeModal();
  };

  const saldoDisponivel =
    selectedOption === "Pix/Boleto" ? saldoPix : saldoCartao;

  // Verifica se o valor é inválido ou zero
  const isDisabled = valorSaque === "R$ 0,00" || valorSaque === "";

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <h2>Solicitar saque</h2>
      </div>

      <div className="modal-body">
        <div className="select-option">
          <SelectButton
            value={selectedOption}
            onChange={handleOptionChange} // Reseta o valor do input quando muda a opção
            options={options}
          />
        </div>

        <div className="saque-section">
          <p>Saldo disponível: {formatToBRL(saldoDisponivel)}</p>
          <input
            type="text"
            placeholder="Digite o valor do saque"
            value={valorSaque}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="modal-footer">
        <button onClick={closeModal} className="cancel-btn">
          Cancelar
        </button>
        <button
          onClick={handleSaque}
          className="sacar-btn"
          disabled={isDisabled}
        >
          Solicitar
        </button>
      </div>
    </Modal>
  );
};

export default ModalBalance;
