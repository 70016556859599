import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './ProductTable.css'; // Importando o CSS

export default function ProductTable({ products, title }) {

    // Template da coluna de quantidade
    const quantityBodyTemplate = (product) => {
        return <span>{product.quantity}</span>;
    };

    // Cabeçalho do DataTable
    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold">{title}</span>
        </div>
    );

    // Rodapé do DataTable
    const footer = `Total de produtos: ${products ? products.length : 0}`;

    return (
        <div className="product-table">
            <DataTable value={products} header={header} footer={footer} tableStyle={{ minWidth: '100%' }}>
                <Column field="name" header="Produto"></Column>
                <Column field="quantity" header="Quantidade" body={quantityBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}
