import React, { useState } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import QuantityOverviewCard from "components/DashboardComponents/QuantityOverviewCard/QuantityOverviewCard";
import BalanceStatistics from "components/DashboardComponents/BalanceStatistics/BalanceStatistics";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import ModalBalance from "components/DashboardComponents/ModalBalance/ModalBalance";
import BasicButton from "components/BasicButton/BasicButton";
import { ReactComponent as Chargeback } from "assets/icons/cancel.svg";
import { ReactComponent as Reembolsado } from "assets/icons/arrow.svg";
import "./MerchantBalance.css";

function MerchantBalance() {
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal

  const data = [
    {
      Value: "273618782",
      Date: "Joãozinho Picafina",
      Status: "R$3340",
    },
  ];

  // Função chamada ao clicar em "Ver mais"
  const handleInfoButtonClick = (rowData) => {
    alert(`Ver mais sobre: ${rowData.Seller}`);
  };

  // Configurações das colunas da tabela
  const columns = [
    { field: "Value", header: "Valor" },
    { field: "Date", header: "Data" },
    { field: "Status", header: "Status" },
  ];

  const salesData = [
    { icon: <Chargeback />, label: "Chargeback", value: 0 },
    { icon: <Reembolsado />, label: "Reembolsos", value: 0 },
    { icon: <Chargeback />, label: "Cancelado", value: 0 },
  ];

  const salesData1 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const salesData2 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const quantityData1 = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const ticket = {
    pix: 0,
    creditCard: 0,
    boleto: 0,
  };

  const handleOpenModal = () => {
    setIsModalOpen(true); // Abre o modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Fecha o modal
  };

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <h1 className="dash-title">Transferências</h1>
        <div className="dash-content-1">
          <BasicButton
            icon="pi pi-dollar" // Ícone de envio do PrimeReact
            label="Solicitar saque"
            onClick={handleOpenModal} // Abre o modal ao clicar
            className="login-button"
          />
        </div>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={salesData1}
                  salesColor="black"
                  totalSoldText="Valor disponível"
                  salesMethodText="Métodos de pagamento selecionados"
                />
                <SalesOverviewCard
                  title="Saldo Pendente"
                  salesData={salesData2}
                  salesColor="black"
                  totalSoldText="Valor Pendente"
                  salesMethodText="Selecione para ver total líquido por método de pagamento"
                />
              </div>
              <div className="overview-cards-2">
                <QuantityOverviewCard
                  title="Total de Pedidos"
                  salesData={quantityData1}
                  salesColor="black"
                  totalSoldText="Quantidade de pedidos"
                  salesMethodText="Selecione para ver quantidade de pedidos por método de pagamento"
                />
                <SalesOverviewCard
                  title="Ticket Médio"
                  salesData={ticket}
                  salesColor="black"
                  totalSoldText="Ticket Médio"
                  salesMethodText="Selecione para ver ticket por método de pagamento"
                />
              </div>
            </div>

            <div className="table-section">
              <CustomDataTable
                data={data}
                columns={columns}
                title="Historícos de saques"
                showInfoColumn={true}
                infoButtonTitle="Ver mais"
                onInfoButtonClick={handleInfoButtonClick}
                searchPlaceholder="Busque por saldo ou status"
              />
            </div>
          </div>
          <div className="merchant-grid-2">
            <BalanceStatistics
              title="Estatísticas de Saque"
              salesData={salesData}
            />
          </div>
        </div>
      </div>
      {/* ModalBalance é aberto ou fechado com base no estado isModalOpen */}
      <ModalBalance
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        saldoPix={0} // Exemplo de saldo para Pix
        saldoCartao={0} // Exemplo de saldo para Cartão de Crédito
      />
    </div>
  );
}

export default MerchantBalance;
