import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import MerchantDashboard from "components/DashboardComponents/MerchantDashboard/MerchantDashboard";
import MerchantTransactions from "components/DashboardComponents/MerchantTransactions/MerchantTransactions";
import MerchantLeads from "components/DashboardComponents/MerchantLeads/MerchantLeads";
import MerchantBalance from "components/DashboardComponents/MerchantBalance/MerchantBalance";
import MerchantTax from "components/DashboardComponents/MerchantTax/MerchantTax";
import MerchantUser from "components/DashboardComponents/MerchantUser/MerchantUser";
import { logout } from "services/authService"; // Importa a função de logout
import './MerchantPanel.css';

function MerchantPanel() {
  const [activeComponent, setActiveComponent] = useState("home");
  const [activeIcon, setActiveIcon] = useState(0);
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");

  const firstSideBarIcons = [
    { iconClass: "pi-home", hasSubmenu: false },
    { iconClass: "pi-shopping-bag", hasSubmenu: false },
    { iconClass: "pi-user", hasSubmenu: false },
    { iconClass: "pi-money-bill", hasSubmenu: false },
  ];

  const secondSideBarMenus = [
    [],
    [],
    [],
    [],
  ];

  
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const topBarIcons = [
    { iconClass: "pi-percentage", component: "tax" },
    { iconClass: "pi-user", component: "user" },
    // { iconClass: "pi-cog", component: "settings" },
    { iconClass: "pi-sign-out", action: handleLogout },
  ];

  const handleIconClick = (iconIndex, hasSubmenu) => {
    setActiveIcon(iconIndex);

    if (!hasSubmenu) {
      if (iconIndex === 0) {
        setActiveComponent("home");
      } else if (iconIndex === 1) {
        setActiveComponent("sales");
      } else if (iconIndex === 2) {
        setActiveComponent("leads");
      } else if (iconIndex === 3) {
        setActiveComponent("transfers");
      }
    } else {
      if (iconIndex === 3) {
        setActiveComponent("moneyBillSubmenu");
      }
    }
  };

  const handleTopBarIconClick = (icon) => {
    if (icon && icon.action) {
      icon.action();
    } else {
      setActiveComponent(icon.component);
    }
  };

  return (
    <div>
      <div className="merchant-content">
        <DashboardLayout
          firstSideBarIcons={firstSideBarIcons}
          secondSideBarMenus={secondSideBarMenus}
          onIconClick={handleIconClick}
          activeIcon={activeIcon}
          topBarIcons={topBarIcons}
          onTopBarIconClick={handleTopBarIconClick}
        >
          {/* Passando userId para todos os componentes */}
          {activeComponent === "home" && <MerchantDashboard userId={userId} />}
          {activeComponent === "sales" && <MerchantTransactions userId={userId} />}
          {activeComponent === "leads" && <MerchantLeads userId={userId} />}
          {activeComponent === "transfers" && <MerchantBalance userId={userId} />}
          {activeComponent === "tax" && <MerchantTax userId={userId} />}
          {activeComponent === "user" && <MerchantUser userId={userId} />}
          {/* {activeComponent === "settings" && <div>Configurações</div>} */}
        </DashboardLayout>
      </div>
    </div>
  );
}

export default MerchantPanel;
