import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from 'pages/Login/Login';
import AdminPanel from 'pages/Admin/AdminPanel';
import LoginAdmin from 'pages/LoginAdmin/LoginAdmin';
import MerchantPanel from 'pages/Merchant/MerchantPanel';
import KYCPage from 'pages/KYC/KYCPage';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import { ThemeProvider } from 'context/ThemeContext';
import 'styles/global.css';
import 'primeicons/primeicons.css'; 
import './App.css';
import MerchantDetails from "pages/Admin/MerchantDetails/MerchantDetails";

function App() {
  return (
    <ThemeProvider> {/* Envolvendo tudo com o ThemeProvider para controle global de tema */ }
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} /> {/* Página de login comum */}
          <Route path="/admin/login" element={<LoginAdmin />} /> {/* Nova rota para o login de admin */}

          {/* Rota protegida para Admin Panel */}
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute requiredRole="Admin">
                <AdminPanel />
              </ProtectedRoute>
            } 
          />

          {/* Rota protegida para Merchant Panel */}
          <Route 
            path="/merchant" 
            element={
              <ProtectedRoute requiredRole="Merchant">
                <MerchantPanel />
              </ProtectedRoute>
            } 
          />

          {/* Nova rota para a página de KYC */}
          <Route 
            path="/kyc" 
            element={<KYCPage />} 
          />

          {/* Rota para detalhes do cliente */}
          <Route 
            path="/admin/clients/:userId" 
            element={<MerchantDetails />} 
          />
          
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
