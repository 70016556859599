import React, { useState } from "react";
import './QuantityOverviewCard.css';

const QuantityOverviewCard = ({ 
  title, 
  salesData, 
  salesColor = "#000", 
  totalSoldText = "Pedidos",
  salesMethodText = "Total de vendas por método"
}) => {
  const [selectedMethods, setSelectedMethods] = useState([]);

  const calculateTotalSales = () => {
    let total = 0;
    if (selectedMethods.includes("pix")) {
      total += salesData.pix;
    }
    if (selectedMethods.includes("creditCard")) {
      total += salesData.creditCard;
    }
    if (selectedMethods.includes("boleto")) {
      total += salesData.boleto;
    }
    return total;
  };

  const handleMethodClick = (method) => {
    if (selectedMethods.includes(method)) {
      setSelectedMethods(selectedMethods.filter((m) => m !== method));
    } else {
      setSelectedMethods([...selectedMethods, method]);
    }
  };

  return (
    <div className="sales-overview-card">
      {/* Cabeçalho */}
      <div className="card-header">
        <span className="title">{title}</span>
      </div>

      {/* Valor total */}
      <div className="card-body">
        <div className="total-sales">
          <h1 style={{ color: salesColor }}>{`${calculateTotalSales()}`}</h1>
          <span>{totalSoldText}</span> {/* Texto editável */}
        </div>

        {/* Total de vendas */}
        <div className="total-sales-info">
          <span>{salesMethodText}</span> {/* Texto editável */}
        </div>

        {/* Seleção de métodos de pagamento com ButtonGroup e classes customizadas */}
        <div className="method-selection">
          <button
            className={`method-button ${selectedMethods.includes("pix") ? "selected" : ""}`}
            onClick={() => handleMethodClick("pix")}
          >
            Pix
          </button>
          <button
            className={`method-button ${selectedMethods.includes("creditCard") ? "selected" : ""}`}
            onClick={() => handleMethodClick("creditCard")}
          >
            Cartão de Crédito
          </button>
          <button
            className={`method-button ${selectedMethods.includes("boleto") ? "selected" : ""}`}
            onClick={() => handleMethodClick("boleto")}
          >
            Boleto
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuantityOverviewCard;
